import { render, staticRenderFns } from "./AppTarget.vue?vue&type=template&id=3d74e2c0&"
import script from "./AppTarget.vue?vue&type=script&lang=js&"
export * from "./AppTarget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import {QSelect} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSelect})
/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src4073529217/src/univoice-company-console/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d74e2c0')) {
      api.createRecord('3d74e2c0', component.options)
    } else {
      api.reload('3d74e2c0', component.options)
    }
    module.hot.accept("./AppTarget.vue?vue&type=template&id=3d74e2c0&", function () {
      api.rerender('3d74e2c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/message/steps/selects/AppTarget.vue"
export default component.exports